import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../services/analytics-adapter";
import {
  getMembershipConfig,
  MemberProfile,
} from "../../../../services/core-api-adapter";
import ButtonWithLoadingState from "../../../ButtonWithLoadingState";
import IconLoader from "../../../IconLoader";

interface MedicalAssistanceCTAProps {
  memberProfile: MemberProfile | null;
  isLoading: boolean;
}

export default function MedicalAssistanceCTA({
  memberProfile,
  isLoading,
}: MedicalAssistanceCTAProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isGetMedicalAssistanceSupported, setIsGetMedicalAssistanceSupported] =
    useState<boolean | null>(null);

  useEffect(() => {
    if (isLoading === false) {
      const membershipConfig = getMembershipConfig(
        memberProfile?.membership?.productCode
      );

      setIsGetMedicalAssistanceSupported(
        membershipConfig?.isGetMedicalAssistanceSupported || false
      );
    }
  }, [memberProfile, isLoading]);

  function onGetMedicalAssistanceClick() {
    trackEvent({
      event: "action.getMedicalAssistanceInitiated",
      "getMedicalAssistance.source": "Home screen",
    });

    trackUserInteraction({
      linkText: "Home screen | getMedicalAssistanceInitiated",
      linkIntent: "navigational",
      linkScope: "button",
    });

    navigate("/home/get-medical-assistance/prompt", {
      state: {
        from: location,
      },
    });
  }

  return (
    <>
      {(isLoading === true || isGetMedicalAssistanceSupported === null) && (
        <ButtonWithLoadingState />
      )}

      <>
        {isGetMedicalAssistanceSupported === true && (
          <>
            <Button
              onClick={onGetMedicalAssistanceClick}
              aria-label={t("Home.GetMedicalAssistanceCTA")}
              color="primary"
              size="large"
              fullWidth
              endIcon={<IconLoader icon="ArrowInCircleIcon" />}
              style={{ justifyContent: "space-between" }}
            >
              <Stack spacing={1} textAlign="left">
                <Typography
                  component="p"
                  variant="h4"
                  color="primary.contrastText"
                >
                  {t("Home.GetMedicalAssistanceCTA")}
                </Typography>
              </Stack>
            </Button>
          </>
        )}

        {isGetMedicalAssistanceSupported === false && (
          <Button onClick={onGetMedicalAssistanceClick}>
            {t("Home.NeedMedicalAssistanceCTA")}
          </Button>
        )}
      </>
    </>
  );
}

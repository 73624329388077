import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useGlobalStore } from "../../store";
import {
  defaultFullscreenPageStyling,
  pageWithBottomNavigationStyling,
} from "../../theme";
import BottomNavigation from "../BottomNavigation";
import useGetMemberProfile from "../../hooks/useGetMemberProfile";
import ThirdPartyMemberDashboard from "./components/ThirdPartyMemberDashboard";
import PayGMemberDashboard from "./components/PayGMemberDashboard";
import B2BMemberDashboard from "./components/B2BMemberDashboard";

export default function Dashboard() {
  const navigate = useNavigate();
  const { state, dispatch } = useGlobalStore();

  const isThirdPartyMember = state.isThirdPartyMember;
  const { memberProfile, memberProfileFetchError, isMemberProfileLoading } =
    useGetMemberProfile();

  const [isPAYGProduct, setIsPayGProduct] = useState<boolean>(false);

  useEffect(() => {
    setIsPayGProduct(memberProfile?.membership?.productCode === "UNU01");
    const controller = new AbortController();

    if (!state.hasMemberRequiredActionsBeenCompleted) {
      navigate("/member-required-actions");
    } else if (state.redirectLink) {
      navigate(state.redirectLink);
      dispatch({
        type: "CLEAR_REDIRECT_LINK",
      });
    }

    return () => {
      controller.abort();
    };
  }, [
    dispatch,
    memberProfile,
    navigate,
    state.hasMemberRequiredActionsBeenCompleted,
    state.redirectLink,
  ]);

  return (
    <>
      {state.hasMemberRequiredActionsBeenCompleted ? (
        <Stack
          sx={{
            ...defaultFullscreenPageStyling,
            ...pageWithBottomNavigationStyling,
            position: "relative",
          }}
        >
          {isThirdPartyMember ? (
            <ThirdPartyMemberDashboard
              memberProfile={memberProfile}
              isMemberProfileLoading={isMemberProfileLoading}
              memberProfileFetchError={memberProfileFetchError}
            />
          ) : (
            <>
              {isPAYGProduct ? (
                <PayGMemberDashboard
                  memberProfile={memberProfile}
                  isMemberProfileLoading={isMemberProfileLoading}
                  memberProfileFetchError={memberProfileFetchError}
                />
              ) : (
                <B2BMemberDashboard
                  memberProfile={memberProfile}
                  isMemberProfileLoading={isMemberProfileLoading}
                  memberProfileFetchError={memberProfileFetchError}
                />
              )}
            </>
          )}
          <BottomNavigation />
        </Stack>
      ) : null}
    </>
  );
}

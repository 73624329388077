import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme, { shadows } from "../../../../../theme";
import { convertPxToRem } from "../../../../../utils";
import IconLoader from "../../../../IconLoader";
import LoadingSpinner from "../../../../LoadingSpinner";
import { useNavigate } from "react-router";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../../services/analytics-adapter";

interface MyPeopleTileProps {
  membershipId: string | undefined | null;
  dependentsCount: number | undefined | null;
  isError: boolean;
  isLoading: boolean;
}

export default function MyPeopleTile({
  membershipId,
  dependentsCount,
  isError,
  isLoading,
}: MyPeopleTileProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onMyPeopleTileClick() {
    trackEvent({
      event: "action.userNav",
      "userNav.destination": "myMembershipDetails",
      "userNav.via": "homeScreenTile",
    });
    trackUserInteraction({
      linkText: "Home screen tile | My people",
      linkIntent: "navigational",
      linkScope: "button",
    });
    navigate(`/home/memberships/${membershipId}`);
  }

  const maxDependentsCount = (count: number): any => {
    if (count > 9) {
      return "9+";
    }
    return count;
  };

  return (
    <>
      <Stack
        onClick={onMyPeopleTileClick}
        component="button"
        aria-label={t("MyPeopleTile.title")}
        borderRadius={2.5}
        p={2}
        spacing={1}
        justifyContent="space-between"
        minHeight={convertPxToRem(195)}
        sx={{
          background: theme.palette.background.paper,
          boxShadow: shadows.light,
          cursor: "pointer",
          border: "none",
        }}
      >
        {isLoading && (
          <Stack height="100%" justifyContent="center" alignSelf="center">
            <LoadingSpinner />
          </Stack>
        )}

        {!isLoading && isError && (
          <Typography alignSelf="center">
            {t("common.somethingWentWrong")}
          </Typography>
        )}

        {!isLoading && !isError && (
          <>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignSelf="center"
              width="100%"
            >
              <Typography variant="body2" fontWeight="bold">
                {t("MyPeopleTile.title")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" color="primary" />
            </Stack>
            <Stack alignSelf="center">
              <Box position="relative">
                <IconLoader
                  icon="PeopleCardIcon"
                  sx={{
                    fontSize: convertPxToRem(75),
                    color: theme.palette.accent5.main,
                  }}
                />
                <Box
                  bgcolor="accent5.lighter"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    height: theme.spacing(4),
                    right: `-${convertPxToRem(4)}`,
                    bottom: 0,
                    px: 1,
                    position: "absolute",
                    borderRadius: 10,
                    minWidth: theme.spacing(4),
                    fontSize: convertPxToRem(14),
                    fontWeight: 700,
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="accent5.main"
                  >
                    {maxDependentsCount(dependentsCount || 0)}
                  </Typography>
                </Box>
              </Box>
            </Stack>

            <Stack alignSelf="center">
              <Typography variant="body2" sx={{ fontSize: convertPxToRem(11) }}>
                {t("MyPeopleTile.dependentsCount.title", {
                  count: dependentsCount || 0,
                })}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
}

import { Avatar, Box, Stack, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  MembershipCardConfig,
  MembershipStatus,
} from "../../../../services/core-api-adapter";
import { shadows } from "../../../../theme";
import MembershipStatusIndicator from "../../../MembershipStatusIndicator";
import ProductPartnerList from "../../../ProductPartnerList";

const HORIZONTAL_SPACING = 2;

interface Template2Props {
  config: MembershipCardConfig;
}

export default function Template2({ config }: Template2Props) {
  const { t } = useTranslation();

  if (!config) {
    return <></>;
  }

  return (
    <Box
      component="li"
      aria-label={config.friendlyName}
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: shadows.light,
        height: "auto",
        maxWidth: (theme: Theme) => theme.spacing(75),
        width: "100%",
        mx: "auto",
        listStyle: "none",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme: Theme) => theme.palette.royalBlue[900],
          background: (theme: Theme) =>
            `linear-gradient(120.16deg, rgba(255, 255, 255, 0.15) 39.8%, rgba(255, 190, 64, 0) 97.41%), ${theme.palette.royalBlue[900]}`,
        }}
      >
        <Stack
          direction="row"
          sx={{
            borderBottom: (theme) =>
              `1px solid ${theme.palette.secondary[500]}`,
          }}
          pb={1}
          pt={2}
          mx={HORIZONTAL_SPACING}
          textAlign="left"
          alignItems="center"
          spacing={2}
        >
          <Avatar sx={{ width: 45, height: 45 }} />
          <Stack spacing={1} width="100%" color="neutral.contrastText">
            <Typography
              variant="h5"
              aria-label={t("MembershipCard.memberNameHeadingLabel")}
              color="inherit"
            >
              {config.memberFullName}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MembershipStatusIndicator
                status={config.status as MembershipStatus}
              />
              <Stack>
                <Typography variant="h5" color="inherit">
                  {
                    t("MembershipCard.beneficiaryCodeLabel", {
                      beneficiaryCode: config.beneficiaryCode,
                    }) as any
                  }
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          justifyContent="space-around"
          px={HORIZONTAL_SPACING}
          pt={1}
          pb={2}
          sx={{ height: "100%" }}
        >
          <Stack spacing={2} color="neutral.contrastText">
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Stack spacing={0.5}>
                <Typography variant="h6" color="inherit">
                  {t("MembershipCard.planTypeLabelExtended")}
                </Typography>
                <Typography variant="h4" color="inherit">
                  {config.friendlyName}
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack spacing={0.5}>
                <Typography component="p" color="inherit">
                  {t("MembershipCard.membershipNumberLabel")}
                </Typography>
                <Typography component="p" variant="h4" color="inherit">
                  {config.membershipNumber}
                </Typography>
              </Stack>
              {config.pharmacySwitchCode && (
                <Stack
                  spacing={0.5}
                  aria-label={t("MembershipCard.pharmacySwitchCodeLabel")}
                >
                  <Typography variant="h6" color="inherit">
                    {t("MembershipCard.pharmacySwitchCodeLabel")}
                  </Typography>
                  <Typography align="right" variant="h4" color="inherit">
                    {config.pharmacySwitchCode || ""}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Stack
        px={HORIZONTAL_SPACING}
        py={1}
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          background: (theme) => theme.palette.grey[50],
        }}
      >
        <ProductPartnerList product={config.product} />
      </Stack>
    </Box>
  );
}

import DoctorOnDemandPrimarySymptomsScroller from ".";
import {
  DoctorOnDemandPrimarySymptom,
  DoctorOnDemandPrimarySymptomOption,
  DoctorOnDemandPrimarySymptomStatus,
} from "../../../../../../services/core-api-adapter";

const primarySymptoms: DoctorOnDemandPrimarySymptom[] = [
  {
    id: "ee15c3f0-a2fc-4c9c-8a80-2c60fcf77823",
    name: DoctorOnDemandPrimarySymptomOption.SKIN_CONDITIONS,
    friendlyName: "SKIN_CONDITIONS",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "d24afc9c-03b2-474c-b710-0b46e57b4bc1",
    name: DoctorOnDemandPrimarySymptomOption.EYE_INFECTIONS,
    friendlyName: "EYE_INFECTIONS",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "f5bc7cfd-9283-4033-bcd1-842dae372530",
    name: DoctorOnDemandPrimarySymptomOption.BLADDER_INFECTION,
    friendlyName: "BLADDER_INFECTION",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "dae0c139-2fac-44a0-bdff-a8f3b0624d36",
    name: DoctorOnDemandPrimarySymptomOption.COLD_FLU,
    friendlyName: "COLD_FLU",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "7697221a-5e92-4a13-8a77-40df6cf5a9a7",
    name: DoctorOnDemandPrimarySymptomOption.VOMITING_DIARRHEA,
    friendlyName: "VOMITING_DIARRHEA",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "896c7a91-5ef5-49fe-b3f4-4eacaa91b206",
    name: DoctorOnDemandPrimarySymptomOption.HEADACHE,
    friendlyName: "HEADACHE",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "fa2dfc8f-8727-446f-a063-556d16e737db",
    name: DoctorOnDemandPrimarySymptomOption.BACK_PAIN,
    friendlyName: "BACK_PAIN",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: DoctorOnDemandPrimarySymptomOption.CONTRACEPTION,
    friendlyName: "CONTRACEPTION",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "ecd372e4-2a23-44e4-a4e2-2ee556cc8020",
    name: DoctorOnDemandPrimarySymptomOption.GENITAL_INFECTIONS,
    friendlyName: "GENITAL_INFECTIONS",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
  {
    id: "4b00a459-72e5-4c7e-bd46-3d63e129078b",
    name: DoctorOnDemandPrimarySymptomOption.GENERAL_CONDITIONS,
    friendlyName: "GENERAL_CONDITIONS",
    status: DoctorOnDemandPrimarySymptomStatus.ACTIVE,
  },
];

export default function DoctorOnDemandPrimarySymptomsScrollerExamples() {
  return (
    <DoctorOnDemandPrimarySymptomsScroller
      primarySymptoms={primarySymptoms}
      onPrimarySymptomClick={() => console.log("On click")}
    />
  );
}

import { Grid, Stack } from "@mui/material";
import { convertPxToRem } from "../../../../../../utils";
import DoctorOnDemandPrimarySymptomsPill from "../../../../../DoctorOnDemandPrimarySymptomsPill";
import { DoctorOnDemandPrimarySymptom } from "../../../../../../services/core-api-adapter";
import { useRef } from "react";
import { trackUserInteraction } from "@/services/analytics-adapter";

interface DoctorOnDemandPrimarySymptomsScrollerProps {
  primarySymptoms: DoctorOnDemandPrimarySymptom[];
  onPrimarySymptomClick: (primarySymptom: DoctorOnDemandPrimarySymptom) => void;
}

interface ScrollableContainer {
  isMouseDown: boolean;
  startX: number;
  scrollLeft: number;
}

export default function DoctorOnDemandPrimarySymptomsScroller({
  onPrimarySymptomClick,
  primarySymptoms,
}: DoctorOnDemandPrimarySymptomsScrollerProps) {
  const containerRef = useRef<HTMLDivElement & Partial<ScrollableContainer>>(
    null
  );

  const handleMouseDown = (e: any) => {
    if (containerRef.current) {
      containerRef.current.isMouseDown = true;
      containerRef.current.startX = e.pageX - containerRef.current.offsetLeft;
    }
  };

  const handleMouseLeave = () => {
    if (containerRef.current) {
      containerRef.current.isMouseDown = false;
    }
  };

  const handleMouseUp = () => {
    if (containerRef.current) {
      containerRef.current.isMouseDown = false;
    }
  };

  const handleMouseMove = (e: any) => {
    if (!containerRef?.current?.isMouseDown) {
      return;
    }
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - (containerRef.current.startX ?? 0)) * 2;
    containerRef.current.scrollLeft = containerRef.current.scrollLeft - walk;
  };

  function onPrimarySymptomPillClick(
    primarySymptom: DoctorOnDemandPrimarySymptom
  ) {
    trackUserInteraction({
      linkText: `DoctorOnDemandPrimarySymptomsModal | ${primarySymptom.name}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    onPrimarySymptomClick(primarySymptom);
  }

  return (
    <Stack
      ref={containerRef}
      sx={{
        display: "flex",
        overflowX: "auto",
        paddingLeft: 2,
        paddingBottom: 5,
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollBehavior: "smooth",
      }}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        sx={{
          "& > :last-child": {
            paddingRight: 2,
          },
        }}
      >
        {primarySymptoms.map((primarySymptom) => (
          <Grid item key={primarySymptom.id}>
            <DoctorOnDemandPrimarySymptomsPill
              onClick={() => onPrimarySymptomPillClick(primarySymptom)}
              primarySymptom={primarySymptom}
              titleStyles={{ fontSize: convertPxToRem(11), fontWeight: 600 }}
              shouldShowSubtitle={false}
              shouldShowArrow={false}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

import { Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import theme, { shadows } from "../../../../../theme";
import { convertPxToRem } from "../../../../../utils";
import IconLoader from "../../../../IconLoader";
import LoadingSpinner from "../../../../LoadingSpinner";
import { useNavigate } from "react-router";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../../services/analytics-adapter";

interface BenefitsTileProps {
  benefitCount: number | undefined | null;
  isLoading: boolean;
  isError: boolean;
}

export default function BenefitsTile({
  benefitCount,
  isLoading,
  isError,
}: BenefitsTileProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onBenefitsTileClick() {
    trackEvent({
      event: "action.userNav",
      "userNav.destination": "myBenefits",
      "userNav.via": "homeScreenTile",
    });
    trackUserInteraction({
      linkText: "Home screen tile | My benefits",
      linkIntent: "navigational",
      linkScope: "button",
    });
    navigate("/home/my-benefits/benefits");
  }

  return (
    <>
      <Stack
        onClick={onBenefitsTileClick}
        component="button"
        aria-label={t("BenefitsTile.title")}
        borderRadius={2.5}
        p={2}
        spacing={1}
        justifyContent="space-between"
        minHeight={convertPxToRem(195)}
        sx={{
          background: theme.palette.background.paper,
          boxShadow: shadows.light,
          cursor: "pointer",
          border: "none",
        }}
      >
        {isLoading && (
          <Stack height="100%" justifyContent="center" alignSelf="center">
            <LoadingSpinner />
          </Stack>
        )}

        {!isLoading && isError && (
          <Typography alignSelf="center">
            {t("common.somethingWentWrong")}
          </Typography>
        )}

        {!isLoading && !isError && (
          <>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignSelf="center"
              alignContent="space-between"
              width="100%"
            >
              <Typography variant="body2" fontWeight="bold">
                {t("BenefitsTile.title")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" color="primary" />
            </Stack>
            <Stack alignSelf="center">
              <IconLoader
                icon="ShieldWithHeartAndUnuFace"
                sx={{
                  fontSize: convertPxToRem(70),
                  color: theme.palette.accent1.main,
                }}
              />
            </Stack>
            <Stack alignSelf="center">
              <Typography variant="body2" sx={{ fontSize: convertPxToRem(11) }}>
                {benefitCount && benefitCount === 1 ? (
                  <>{t("BenefitsTile.subtitle.one")}</>
                ) : (
                  <Trans
                    i18nKey="BenefitsTile.subtitle.many"
                    values={{
                      count: benefitCount ? benefitCount : 0,
                    }}
                  ></Trans>
                )}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
}

import TileWithServiceUsage from "./components/TIleWithServiceUsage";
import { Box, Stack, Typography } from "@mui/material";
import IconLoader from "../../../../IconLoader";
import {
  convertPxToRem,
  isFaceScanCompatibleDevice,
} from "../../../../../utils";
import { Trans, useTranslation } from "react-i18next";
import Default from "./components/DefaultTile";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../../services/analytics-adapter";
import theme, { shadows } from "../../../../../theme";
import { useNavigate } from "react-router";
import { MemberProfile } from "../../../../../services/core-api-adapter";

interface HealthCheckTileProps {
  memberProfile: MemberProfile | null;
  isLoading: boolean;
  shouldShowServiceUsage: boolean;
}

export default function HealthCheckTile({
  memberProfile,
  isLoading,
  shouldShowServiceUsage,
}: HealthCheckTileProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function checkFaceScanCompatibility(): boolean {
    const {
      isIOSWithNonSafariBrowser,
      isAndroidWithNonChromeOrSamsungBrowser,
    } = isFaceScanCompatibleDevice();
    return !(
      isIOSWithNonSafariBrowser || isAndroidWithNonChromeOrSamsungBrowser
    );
  }

  const isCompatibleDevice = checkFaceScanCompatibility();

  function onHealthCheckTileClick() {
    trackEvent({
      event: "action.userNav",
      "userNav.destination": "doAHealthCheck",
      "userNav.via": "homeScreenTile",
    });
    trackUserInteraction({
      linkText: "Home screen tile | Do a health check",
      linkIntent: "navigational",
      linkScope: "button",
    });
    navigate("/binah-scan?skipScanPrompt=true");
  }

  return (
    <Stack
      onClick={onHealthCheckTileClick}
      component="button"
      aria-label={
        isCompatibleDevice
          ? t("HealthCheckTile.compatible.title")
          : t("HealthCheckTile.notCompatible.title")
      }
      borderRadius={2.5}
      p={2}
      spacing={1}
      justifyContent="space-between"
      minHeight={convertPxToRem(195)}
      sx={{
        background: theme.palette.background.paper,
        boxShadow: shadows.light,
        cursor: "pointer",
        border: "none",
      }}
    >
      {!isCompatibleDevice && (
        <>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="space-between"
            width="100%"
            textAlign="left"
          >
            <Typography variant="body2" fontWeight="bold">
              {t("HealthCheckTile.notCompatible.title")}
            </Typography>
            <IconLoader icon="ChevronNextIcon" color="primary" />
          </Stack>
          <Stack alignSelf="center">
            <Box position="relative">
              <IconLoader
                icon="UnuFaceWithRectangleIcon"
                sx={{
                  fontSize: convertPxToRem(70),
                }}
              />
              <Box
                bgcolor="primary.light"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  height: theme.spacing(4),
                  right: `-${convertPxToRem(10)}`,
                  bottom: convertPxToRem(1),
                  px: 1,
                  position: "absolute",
                  borderRadius: 10,
                  minWidth: theme.spacing(4),
                  fontSize: convertPxToRem(14),
                  fontWeight: 700,
                  outline: `${theme.palette.background.paper} solid ${convertPxToRem(4)}`,
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="background.paper"
                >
                  ?
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Stack alignSelf="center">
            <Typography variant="body2" sx={{ fontSize: convertPxToRem(11) }}>
              <Trans i18nKey="HealthCheckTile.notCompatible.subtitle"></Trans>
            </Typography>
          </Stack>
        </>
      )}

      {isCompatibleDevice && (
        <>
          {shouldShowServiceUsage === true ? (
            <TileWithServiceUsage
              availableHealthCheckCount={
                memberProfile?.memberServiceOfferingUsageCount
              }
              isLoading={isLoading}
            />
          ) : (
            <Default />
          )}
        </>
      )}
    </Stack>
  );
}

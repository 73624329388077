import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme, { shadows } from "../../theme";
import IconLoader from "../IconLoader";
import { DoctorOnDemandPrimarySymptom } from "../../services/core-api-adapter";
import { convertPxToRem } from "../../utils";

interface PrimarySymptomPillMap {
  [key: string]: any;
}

const primarySymptomIconStyles = {
  width: convertPxToRem(36),
  height: convertPxToRem(36),
};

const primarySymptomPillMap: PrimarySymptomPillMap = {
  SKIN_CONDITIONS: {
    title: "DoctorOnDemand.primarySymptoms.skinConditions.title",
    subtitle: "DoctorOnDemand.primarySymptoms.skinConditions.subtitle",
    icon: (
      <IconLoader icon="SkinConditionsIcon" sx={primarySymptomIconStyles} />
    ),
  },
  EYE_INFECTIONS: {
    title: "DoctorOnDemand.primarySymptoms.eyeInfection.title",
    subtitle: "DoctorOnDemand.primarySymptoms.eyeInfection.subtitle",
    icon: <IconLoader icon="EyeInfectionIcon" sx={primarySymptomIconStyles} />,
  },
  BLADDER_INFECTION: {
    title: "DoctorOnDemand.primarySymptoms.bladderInfection.title",
    subtitle: "DoctorOnDemand.primarySymptoms.bladderInfection.subtitle",
    icon: (
      <IconLoader icon="BladderInfectionIcon" sx={primarySymptomIconStyles} />
    ),
  },
  COLD_FLU: {
    title: "DoctorOnDemand.primarySymptoms.coldFlu.title",
    subtitle: "DoctorOnDemand.primarySymptoms.coldFlu.subtitle",
    icon: <IconLoader icon="ColdAndFluIcon" sx={primarySymptomIconStyles} />,
  },
  VOMITING_DIARRHEA: {
    title: "DoctorOnDemand.primarySymptoms.vomitingDiarrhoea.title",
    subtitle: "DoctorOnDemand.primarySymptoms.vomitingDiarrhoea.subtitle",
    icon: (
      <IconLoader
        icon="VomitingAndDiarrhoeaIcon"
        sx={primarySymptomIconStyles}
      />
    ),
  },
  HEADACHE: {
    title: "DoctorOnDemand.primarySymptoms.headache.title",
    subtitle: "DoctorOnDemand.primarySymptoms.headache.subtitle",
    icon: <IconLoader icon="HeadacheIcon" sx={primarySymptomIconStyles} />,
  },
  BACK_PAIN: {
    title: "DoctorOnDemand.primarySymptoms.backPain.title",
    subtitle: "DoctorOnDemand.primarySymptoms.backPain.subtitle",
    icon: <IconLoader icon="BackPainIcon" sx={primarySymptomIconStyles} />,
  },
  CONTRACEPTION: {
    title: "DoctorOnDemand.primarySymptoms.contraception.title",
    subtitle: "DoctorOnDemand.primarySymptoms.contraception.subtitle",
    icon: <IconLoader icon="ContraceptionIcon" sx={primarySymptomIconStyles} />,
  },
  GENITAL_INFECTIONS: {
    title: "DoctorOnDemand.primarySymptoms.genitalInfections.title",
    subtitle: "DoctorOnDemand.primarySymptoms.genitalInfections.subtitle",
    icon: (
      <IconLoader
        icon="SexualTransmittedDiseasesIcon"
        sx={primarySymptomIconStyles}
      />
    ),
  },
  GENERAL_CONDITIONS: {
    title: "DoctorOnDemand.primarySymptoms.generalConditions.title",
    subtitle: "DoctorOnDemand.primarySymptoms.generalConditions.subtitle",
    icon: (
      <IconLoader
        icon="GeneralDoctorConsultIcon"
        sx={primarySymptomIconStyles}
      />
    ),
  },
};

interface DoctorOnDemandPrimarySymptomsPillProps {
  onClick: () => void;
  primarySymptom: DoctorOnDemandPrimarySymptom;
  shouldShowSubtitle?: boolean;
  titleStyles?: any;
  shouldShowArrow?: boolean;
}

export default function DoctorOnDemandPrimarySymptomsPill({
  onClick,
  primarySymptom,
  shouldShowSubtitle = true,
  titleStyles,
  shouldShowArrow = true,
}: DoctorOnDemandPrimarySymptomsPillProps) {
  const { t } = useTranslation();

  const data = primarySymptomPillMap[primarySymptom.name];

  return (
    <>
      {data ? (
        <Button
          key={data.title}
          variant="contained"
          color="info"
          aria-label={t(data.title)}
          sx={{
            justifyContent: "flex-start",
            boxShadow: shadows.light,
            padding: 1,
            "&:hover, &:focus": {
              boxShadow: shadows.light,
              backgroundColor: theme.palette.primary[100],
            },
          }}
          fullWidth
          onClick={onClick}
        >
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Stack
              display="flex"
              flexDirection="row"
              gap={2}
              alignItems="center"
            >
              <Stack>{data.icon}</Stack>
              <Stack textAlign="left" style={{ marginTop: 0 }}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    ...titleStyles,
                  }}
                >
                  {t(data.title)}
                </Typography>
                {shouldShowSubtitle && (
                  <Typography fontSize={convertPxToRem(12)}>
                    {t(data.subtitle)}
                  </Typography>
                )}
              </Stack>
            </Stack>

            {shouldShowArrow && <IconLoader icon="ChevronNextIcon" />}
          </Stack>
        </Button>
      ) : null}
    </>
  );
}

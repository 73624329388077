import { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import IconLoader from "../../../../../IconLoader";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../../../services/analytics-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../../../../../services/feature-toggle-adapter";
import ButtonWithLoadingState from "../../../../../ButtonWithLoadingState";
import { convertPxToRem } from "../../../../../../utils";
import { MemberProfile } from "../../../../../../services/core-api-adapter";

interface PayGMedicalAssistanceCTAProps {
  memberProfile: MemberProfile | null;
  isLoading: boolean;
}

export default function PayGMedicalAssistanceCTA({
  memberProfile,
  isLoading,
}: PayGMedicalAssistanceCTAProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasActiveSessions, setHasActiveSessions] = useState<boolean | null>(
    null
  );

  function onGetMedicalAssistanceButtonClick() {
    trackEvent({
      event: "action.getMedicalAssistanceInitiated",
      "getMedicalAssistance.source": "Home screen",
    });

    trackUserInteraction({
      linkText: "Home screen | getMedicalAssistanceInitiated",
      linkIntent: "navigational",
      linkScope: "button",
    });

    navigate("/home/get-medical-assistance/intercare");
  }

  useEffect(() => {
    if (memberProfile?.consultation.consultationResponses) {
      setHasActiveSessions(
        memberProfile.consultation.consultationResponses.length > 0
      );
    }
  }, [memberProfile, isLoading]);

  return (
    <>
      {isLoading === true && (
        <ButtonWithLoadingState sx={{ height: `${convertPxToRem(78)}` }} />
      )}

      {isLoading === false && (
        <>
          {hasActiveSessions === true && (
            <Button
              onClick={onGetMedicalAssistanceButtonClick}
              aria-label={t("GetMedicalAssistanceCTAButton.continue.label")}
              color="primary"
              size="large"
              fullWidth
              endIcon={<IconLoader icon="ArrowInCircleIcon" />}
              style={{ justifyContent: "space-between" }}
            >
              <Stack spacing={1} textAlign="left">
                <Typography variant="h4" color="primary.contrastText">
                  {t("GetMedicalAssistanceCTAButton.continue.label")}
                </Typography>
                {hasActiveSessions && (
                  <Typography
                    component="p"
                    variant="h5"
                    color="primary.contrastText"
                  >
                    {t("GetMedicalAssistanceCTAButton.continue.subtitle")}
                  </Typography>
                )}
              </Stack>
            </Button>
          )}

          {hasActiveSessions === false && (
            <>
              {isFeatureEnabled(
                FeatureToggleIdentifier.ENABLE_DOCTOR_ON_DEMAND
              ) ? (
                <Button
                  onClick={onGetMedicalAssistanceButtonClick}
                  aria-label={t(
                    "GetMedicalAssistanceCTAButton.doctorOnDemand.label"
                  )}
                  color="primary"
                  size="large"
                  fullWidth
                  endIcon={<IconLoader icon="ArrowInCircleIcon" />}
                  style={{ justifyContent: "space-between" }}
                >
                  <Stack spacing={1} textAlign="left">
                    <Typography
                      component="p"
                      variant="h4"
                      color="primary.contrastText"
                    >
                      {t("GetMedicalAssistanceCTAButton.doctorOnDemand.label")}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      fontWeight="bold"
                      color="primary.contrastText"
                      style={{ marginTop: 0 }}
                    >
                      <Trans
                        i18nKey="GetMedicalAssistanceCTAButton.doctorOnDemand.priceText"
                        values={{
                          amount1:
                            memberProfile?.consultation.consultationRates[0]
                              ?.amountFormatted,
                          amount2:
                            memberProfile?.consultation.consultationRates[1]
                              ?.amountFormatted,
                        }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          fontWeight="bold"
                          color="primary.contrastText"
                        ></Typography>
                      </Trans>
                    </Typography>
                  </Stack>
                </Button>
              ) : (
                <Button
                  onClick={onGetMedicalAssistanceButtonClick}
                  aria-label={t("GetMedicalAssistanceCTAButton.label")}
                  color="primary"
                  size="large"
                  fullWidth
                  endIcon={<IconLoader icon="ArrowInCircleIcon" />}
                  style={{ justifyContent: "space-between" }}
                >
                  <Stack spacing={1} textAlign="left">
                    <Typography
                      component="p"
                      variant="h4"
                      color="primary.contrastText"
                    >
                      {t("GetMedicalAssistanceCTAButton.title")}
                    </Typography>
                  </Stack>
                </Button>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
